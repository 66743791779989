// Vendor
import '@/helpers/bugsnag';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/rias/ls.rias';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'simplebar'; // @TODO import only where it needed

// Core
import '@/scripts/core/a11y';

// Components
import '@/scripts/components/utm'; // @TODO do we need it?
import '@/scripts/components/drawers'; // @TODO import only where needed
import '@/scripts/components/cart'; // @TODO check what we can do with [data-product-submit] property
import '@/scripts/components/quick-view'; // @TODO try to rewrite to new sections code-base
import '@/scripts/components/zoom'; // @TODO import only on PDP if it is required here

// Snippets
import '@/helpers/video';
import '@/helpers/klaviyo';
import '@/scripts/snippets/modal'; // @TODO check impact of changes if we remove it from here
import '@/scripts/snippets/custom-dropdown'; // @TODO import only it is needed
import '@/scripts/components/modal-product-details';
import '@/scripts/components/recommended-badge';
import '@/scripts/apps/rebuy';

import sections from '@packages/sections/theme-sections';
import { createReview, createSubmission, finalizeSubmission, setSubmissionCustomer } from '@/api/fera';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

(async () => {
    const { default: lozad } = await import('lozad');

    if (!lozad) {
        return;
    }

    const imageObserver = lozad('img.lozad', {
        rootMargin: '10px 0px',
        threshold: 0.1,
        enableAutoReload: true,
        loaded(image) {
            image?.classList?.add('image--loaded');
        },
    });

    const imageMutationObserver = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation?.type !== 'childList') {
                return;
            }

            const addedNodes = mutation?.addedNodes;
            if (!addedNodes) {
                return;
            }

            if (!addedNodes?.length) {
                return;
            }

            mutation?.addedNodes?.forEach((node) => {
                if (!!node?.classList?.contains('lozad')) {
                    // New `.lozad` element added, observe it with Lozad
                    imageObserver?.triggerLoad(node);
                }

                // Check if descendants of the added node also have `.lozad` class
                if (node?.nodeType !== 1) {
                    return;
                }

                const descendants = node?.querySelectorAll('.lozad');
                if (!descendants) {
                    return;
                }

                if (!descendants?.length) {
                    return;
                }

                descendants?.forEach((descendant) => {
                    imageObserver?.triggerLoad(descendant);
                });
            });
        }
    });

    imageMutationObserver?.observe(document.body, {
        childList: true,
        subtree: true,
    });

    imageObserver?.observe();
})();

document.addEventListener('DOMContentLoaded', async () => {
    await sections.asyncLoadOnUserInteraction();
});

document.addEventListener('DOMContentLoaded', () => {
    const triggers = document.querySelectorAll('[data-open-search]');
    if (!triggers) {
        return;
    }

    if (!triggers?.length) {
        return;
    }

    triggers?.forEach((trigger) => {
        trigger?.addEventListener('click', (event) => {
            event?.preventDefault();
            event?.stopPropagation();
            event?.stopImmediatePropagation();

            document.dispatchEvent(new CustomEvent('open:search'));
        });
    });
});

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('submit', async (event) => {
        const form = event.target;
        if (Boolean(form?.getAttribute('data-review-form')) === false) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());

        const visitor_id = uuidv4();

        try {
            const submission = await createSubmission({
                visitor_id,
            }).then((response) => {
                return response.data;
            });

            await createReview({
                token: submission.token,
                body: data.body,
                heading: data.heading,
                ...(data.product_id ? {
                    product_id: data.product_id,
                } : {}),
                rating: data.rating,
                ...(!!data?.media?.size ? {
                    media: [
                        {
                            name: data?.media?.name,
                            size: data?.media?.size,
                            type: data?.media?.type,
                            data: await toBase64(data?.media),
                        },
                    ],
                } : {}),
            }).then((response) => {
                return response.data;
            });

            await setSubmissionCustomer({
                token: submission.token,
                display_name: data?.display_name || '',
                email: data?.email || '',
                full_name: data?.full_name || '',
                visitor_id,
                ...(!!window?.__st?.cid ? {
                    customer_id: window.__st.cid,
                    external_customer_id: window.__st.cid,
                } : {}),
            }).then((response) => {
                return response.data;
            });

            await finalizeSubmission({
                token: submission.token,
            });

            const modal = form.closest('[data-modal]');
            if (!modal) {
                return;
            }

            modal?.classList?.remove('account-dashboard-modal--opened');

            setTimeout(() => {
                modal?.remove();
            }, 400);
        } catch (error) {
            console.log(error);
        }
    });

    document.addEventListener('click', async (event) => {
        let button = event.target;
        if (!button?.matches(`[data-leave-review-button]`)) {
            button = button.closest(`[data-leave-review-button]`);
        }

        if (!button) {
            return;
        }

        const handle = button?.getAttribute('data-leave-review-button');
        if (!handle) {
            return;
        }

        const response = await axios.get(`/products/${handle}`, {
            params: {
                view: 'leave-a-review'
            },
        }).then((response) => {
            return response.data;
        }) || '';

        if (!response) {
            return;
        }

        const modal = document.createElement('div');
        modal.innerHTML = response;

        document.body.appendChild(modal);
    });

    document.addEventListener('click', async (event) => {
        let button = event.target;
        if (!button?.matches(`[data-leave-store-review-button]`)) {
            button = button.closest(`[data-leave-store-review-button]`);
        }

        if (!button) {
            return;
        }

        const response = await axios.get(`/`, {
            params: {
                view: 'leave-a-review'
            },
        }).then((response) => {
            return response.data;
        }) || '';

        if (!response) {
            return;
        }

        const modal = document.createElement('div');
        modal.innerHTML = response;

        document.body.appendChild(modal);
    });
});
